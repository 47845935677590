import { ApolloError } from '@apollo/client/core';
import { ErrorResponse } from '@apollo/client/link/error';
import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';

import { MessageService } from '../message/index';
import { GlobalService, ERROR_TYPES } from '../global/index';
import { ModalBridgeService } from 'app/shared/idle';
import { EnvironmentService } from 'app/shared/environment/environment.service';
import { UserLocalStorageService } from 'app/shared/user';

const SENTRY_URI = 'https://55d347312a084c97ba2cd7cd2bb917e6@sentry.io/249160';

if (!location.hostname.endsWith('localhost')) {
  Sentry.init({
    dsn: SENTRY_URI,
    // `release` will be replaced by actual version and hash during build (pre-build.js)
    release: `9.1.7@3e98e35081ab865ec10c4450b50cddd751f900fc`,
    beforeSend(event: Sentry.Event, hint?: Sentry.EventHint): Sentry.Event {
      // Do not report any http type 401 errors.
      if (event.exception && event.exception.values) {
        const has401Error = !!event.exception.values.find(err => {
          if (err && err.value) {
            return err.value.search('401') !== -1;
          } else {
            return false;
          }
        });
        if (has401Error) {
          return null;
        }
      }
      return event;
    },
    debug: false,
    attachStacktrace: true,
  });
}

@Injectable()
export class HyperionErrorHandler implements ErrorHandler {
  /** Error messages to be hidden from the user */
  private ignoreSubStrings = [
    'getTime',
    'ng2Idle',
    'Must provide an operation',
    'Unable to get property',
    'Cannot read property',
    'captureException',
    'is null',
    'Expression has changed',
    'Cannot read properties of undefined',
  ];

  constructor(
    private messageService: MessageService,
    private globalService: GlobalService,
    private modalBridgeService: ModalBridgeService,
    private environmentService: EnvironmentService,
    private userLocalStorageService: UserLocalStorageService
  ) {}

  public handleError(error: ApolloError | ErrorResponse | any): void {
    if (location.hostname === 'localhost') {
      console.error(error);
    }

    if (typeof error === 'object') {
      this.handleErrorObject(error);
    } else if (typeof error === 'string') {
      this.handleErrorString(error);
    }
  }

  public handleAuthError(): void {
    this.modalBridgeService.triggerModal('locked');
  }

  /**
   * Handles the error behavior when error is an string
   *
   * @param error the error to handle
   */
  private handleErrorString(error: string): void {
    if (error.search('401') !== -1) {
      this.handleAuthError();
    } else {
      this.reportErrorToIntegrations(error);
    }
  }

  /**
   * Handles the error behavior when error is an object
   * @param error the error to handle
   */
  private handleErrorObject(error: ApolloError | any): void {
    const errorType = this.globalService.identifyErrorCode(error);

    switch (errorType) {
      case ERROR_TYPES.AUTH_ERROR:
        return this.handleAuthError();
      case ERROR_TYPES.UNAVAILABLE:
        window.location.reload();
        return;
      default:
        if (error instanceof ApolloError) {
          this.handleApolloError(error as ApolloError);
          return;
        } else {
          this.reportErrorToIntegrations(error);
        }
    }
  }

  private handleApolloError(error: ApolloError): void {
    if (error.networkError) {
      return;
    }
    this.showErrorMessage(error.message);
  }

  /**
   * Reports the error to the different integrations
   *
   * @param error the error object
   */
  private reportErrorToIntegrations(error: any): void {
    this.reportToSentry(error);
    this.reportToNewRelic(error);
  }

  /**
   * Attempts to show the error message to the user.
   *
   * @param error the error message
   */
  private showErrorMessage(error: string): void {
    if (this.shouldShowErrorToUser(error)) {
      this.messageService.insertData({
        textArray: error.split('\n'),
        type: 'error',
      });
    }
  }

  /**
   * Checks if the message should be displayed to the user
   * We do this by checking if any element from the ignore list is contained inside the message
   *
   * @param message the error message.
   */
  private shouldShowErrorToUser(message: string): boolean {
    try {
      return this.ignoreSubStrings.every(
        ignoreString => message?.search(ignoreString) === -1
      );
    } catch (_e: any) {
      const e = new Error('Invalid message: ' + message.toString(), {
        cause: _e,
      });
      this.reportToSentry(e);
      return false;
    }
  }

  /**
   * Reports an error to sentry
   * @param error the error to be reported
   * @returns sentry.io generated eventid.
   */
  private reportToSentry(error: any): string {
    const companyName = localStorage.getItem('MEcompanyName');
    const companyId = localStorage.getItem('MEcompanyId');
    const environment = this.environmentService.getEnvironmentType();
    const userId = this.userLocalStorageService.getMEUser().id;
    const gqlOperationName = this.getGQLOperationName(error);

    Sentry.configureScope(scope => {
      scope.setUser({ id: userId });

      scope.setTags({
        userId,
        companyId,
        companyName,
        environment,
        gqlOperationName,
      });
    });

    return Sentry.captureException(error.originalError || error);
  }

  /**
   * Reports an error to NewRelic if it exists in the global scope
   * @param error the error to be reported
   */
  private reportToNewRelic(error: any): void {
    if (window['newrelic']) {
      window['newrelic'].noticeError(error);
    }
  }

  private getGQLOperationName(error: ErrorResponse | any): string {
    if (error && error.operation && error.operation.operationName) {
      return error.operation.operationName;
    }
    return null;
  }
}
